<div class="chat-message__typing">
    <div class="chat-message employee">
        <div class="chat-message__text">
            <div class="chat-message__loading">
                <span></span>
                <span></span>
                <span></span>
            </div>
        </div>
    </div>
</div>
