import {MessageType} from '../enums/message-type';
import {MessageSendingStatus} from '../enums/message-sending-status';

export class Message {
    fileId: number;
    fileType: string;
    aiAssistant: boolean;

    constructor(
        public id: number,
        public threadId: number,
        public type: MessageType,
        public message: string,
        public reply: boolean,
        public senderId: number,
        public sendingStatus: MessageSendingStatus,
        public date?: Date,
    ) {
    }
}

