import {Component, OnInit} from '@angular/core';
import {PollWidgetService} from "../services/poll-widget.service";
import {Title} from '@angular/platform-browser';
import {PollStyles} from "../common/interfaces/poll-settings.interface";
import {MatSnackBar} from "@angular/material/snack-bar";
import {ApplicationState} from "../common/enums/application-state";
import {ApplicationStateService} from "../services/application-state.service";
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {UserCustomTerm} from "../common/interfaces/widget.interface";
import {FormWidgetService} from "../services/form-widget.service";

@Component({
    selector: 'app-poll',
    templateUrl: './poll.component.html',
    styleUrls: ['./poll.component.scss']
})

export class PollComponent implements OnInit {

    type: string;
    widgetId: string;
    settings;
    questions;
    pollSubmitted: boolean = false;
    pollStyles: PollStyles = {
        titleFontSize: 26,
        questionFontSize: 18,
        answerFontSize: 16,
        backgroundColor: '#f5f5f5'
    };
    hasRequiredQuestion: boolean;
    isPollActive: string;
    appState = ApplicationState;
    customTerms: UserCustomTerm[];
    sendIssue: boolean = false;
    issueFormData = {
        userMessage: '',
        userName: '',
        userLastname: '',
        userEmail: '',
        userPhone: '',
        userCompany: '',
        termCheckboxAccept: false,
        dataCheckboxAccept: false,
        termIds: null,
        termId: []
    };
    sendBtnClicked = false;

    constructor(private pollWidgetService: PollWidgetService,
                private snackBar: MatSnackBar,
                protected applicationStateService: ApplicationStateService,
                private titleService: Title,
                private formWidgetService: FormWidgetService,
                private _sanitizer: DomSanitizer) {
    }

    ngOnInit() {
        const queryParams = new URLSearchParams(window.location.search);
        this.type = queryParams.get('type');
        this.widgetId = queryParams.get('uuid');
        this.getPollSettings(this.widgetId);

        this.applicationStateService.customerData
            .mutate((state: { client_id: string, customer_id: string, external_hash: string }): void => {
                state.customer_id = this.widgetId.split('_')[0];
                state.external_hash = this.widgetId.split('_')[1];
            });
    }

    getPollSettings(widgetId) {
        this.pollWidgetService.getPollWidgetSettings(widgetId).subscribe(
            widgetSettings => {
                if (!widgetSettings.settings) {
                    this.applicationStateService.setState(this.appState.ERROR);
                } else {
                    const parsedSettings = JSON.parse(widgetSettings.settings);

                    this.questions = parsedSettings.settings.questions;
                    this.pollStyles = parsedSettings.settings.styles;
                    this.titleService.setTitle(parsedSettings.name);
                    this.hasRequiredQuestion = this.questions.some(question => question.data.required);
                    this.isPollActive = widgetSettings.is_active;
                    this.customTerms = parsedSettings.content.customUserTerms;
                    this.sendIssue = this.questions.some(question => question.data.pollType === 'offer');

                    if (this.customTerms) {
                        this.customTerms.forEach(term => term.selected = false);
                    }

                    this.anyRequiredFieldEmpty();
                }
            }
        );
    }

    hasContactSection() {
        return this.questions ? this.questions.some(question => question.type === 'contact') : false;
    }

    getContactSection() {
        return this.questions.find(question => question.type === 'contact');
    }

    getHeader() {
        return this.questions.find(question => question.type === 'header');
    }

    range(from: number, to: number): number[] {
        return Array.from({length: to - from}, (_, i) => i + from);
    }

    // tworzenie sprawy na podstawie odpowiedzi z ankiety
    createdPollIssue(cleanedQuestions) {
        this.issueFormData['issueSubject'] = this.titleService.getTitle();

        cleanedQuestions.forEach(question => {
            if (question.type === 'shortQuestion' || question.type === 'longQuestion' || question.type === 'lineScaleQuestion') {
                this.issueFormData['userMessage'] += `<strong>${question.data.title}</strong><br>${question.data.value}<br><br>`;
            }

            if (question.type === 'oneOptionQuestion' || question.type === 'selectQuestion') {
                this.issueFormData['userMessage'] += `<strong>${question.data.title}</strong><br>${question.data.options.find(entry => entry.selected)?.value}<br><br>`;
            }

            if (question.type === 'multipleOptionQuestion') {
                this.issueFormData['userMessage'] += `<strong>${question.data.title}</strong><br>${question.data.options.filter(entry => entry.selected).map(entry => entry.value).join(', ')}<br><br>`;
            }

            if (question.type === 'gridOneQuestion' || question.type === 'gridQuestion') {
                this.issueFormData['userMessage'] += `<strong>${question.data.title}</strong><br>`;

                question.data.rows.forEach((row) => {
                    this.issueFormData['userMessage'] += row?.value + ': ';

                    if (Array.isArray(row?.selected)) {
                        let userMessageValue = [];
                        row.selected.forEach(value => userMessageValue.push(question.data.columns[value]?.value));
                        this.issueFormData['userMessage'] += userMessageValue.join(', ');
                    } else {
                        this.issueFormData['userMessage'] += question.data.columns[row?.selected]?.value;
                    }

                    this.issueFormData['userMessage'] += '<br>';
                });

                this.issueFormData['userMessage'] += '<br>';
            }

            if (question.type === 'contact') {
                this.issueFormData.userName = question.data.find(entry => entry.name === 'firstname')?.value || '';
                this.issueFormData.userLastname = question.data.find(entry => entry.name === 'lastname')?.value || '';
                this.issueFormData.userEmail = question.data.find(entry => entry.name === 'email')?.value || '';
                this.issueFormData.userPhone = question.data.find(entry => entry.name === 'phone')?.value || '';
                this.issueFormData.userCompany = question.data.find(entry => entry.name === 'company')?.value || '';
            }
        });
    }

    onPollDataSubmit() {
        this.sendBtnClicked = true;
        const cleanedQuestions = this.questions.map(question => {
            if (question.type === "header") {
                delete question.data.description;
            } else {
                delete question.uuid
                if (question.data.options) {
                    question.data.options.forEach(option => {
                        delete option.uuid;
                    });
                }
            }
            return question;
        });

        if (this.sendIssue) {
            this.createdPollIssue(cleanedQuestions);
        }

        cleanedQuestions.push({
            type: 'customTerms',
            data: this.customTerms
        });

        const selectedTerms = this.customTerms
            .filter(term => term.selected)
            .map(term => term.id);

        const termsIds = {
            termId: selectedTerms
        };

        this.pollWidgetService.postFormData(cleanedQuestions, termsIds)
            .subscribe(
                response => {
                    if (response.message !== 'OK') {
                        this.snackBar.open('Błąd wysyłki. Wypełnij wymagane pola.', '', {panelClass: ['error-snackbar']});
                    }

                    if (this.sendIssue) {
                        return this.formWidgetService.postFormData(this.issueFormData).subscribe(() => this.pollSubmitted = true);
                    } else {
                        this.pollSubmitted = true
                    }
                },
                error => {
                    if ((error.error?.message || error.error?.message !== '') && typeof error.error?.message === 'string') {
                        this.snackBar.open(error.error.message + '.', '', {panelClass: ['error-snackbar']});
                    } else {
                        this.snackBar.open('Błąd wysyłki. Wypełnij wymagane pola.', '', {panelClass: ['error-snackbar']});
                    }
                }
            );
    }

    updateGridQuestionValue(rowIndex: number, colIndex: number, checked: boolean, uuid) {
        const questionIndex = this.questions.findIndex(question => question.uuid === uuid);

        if (checked) {
            this.questions[questionIndex].data.rows[rowIndex].selected.push(colIndex);
        } else {
            const indexToRemove = this.questions[questionIndex].data.rows[rowIndex].selected.indexOf(colIndex);
            if (indexToRemove !== -1) {
                this.questions[questionIndex].data.rows[rowIndex].selected.splice(indexToRemove, 1);
            }
        }
    }

    updateGridOneQuestionValue(rowIndex: number, colIndex: number, checked: boolean, uuid) {
        const questionIndex = this.questions.findIndex(question => question.uuid === uuid);

        this.questions[questionIndex].data.rows[rowIndex].selected = colIndex;
    }

    anyRequiredFieldEmptyFlag: boolean = false;

    anyRequiredFieldEmpty() {

        if (!this.questions || this.questions.length === 0) {
            this.anyRequiredFieldEmptyFlag = false;
            return false;
        }

        if (this.customTerms && this.customTerms.some(term => !term.selected)) {
            this.anyRequiredFieldEmptyFlag = true;
            return true;
        }

        for (let question of this.questions) {
            if (this.isQuestionEmpty(question)) {
                this.anyRequiredFieldEmptyFlag = true;
                return true;
            }
        }

        this.anyRequiredFieldEmptyFlag = false;
        return false;
    }

    isQuestionEmpty(question) {
        if (!question.data.required) {
            return false;
        }

        switch (question.type) {
            case "shortQuestion":
            case "longQuestion":
                return !question.data.value;

            case "lineScaleQuestion":
                return (question.data.value === null || question.data.value === undefined || question.data.value === "");

            case "oneOptionQuestion":
            case "multipleOptionQuestion":
            case "selectQuestion":
                return !question.data.options.some(option => option.selected);

            case "gridOneQuestion":
                return !question.data.rows.some(col => col.selected === 0 || col.selected > 0);

            case "gridQuestion":
                return !question.data.rows.some(col => col.selected.length > 0);

            default:
                return false;
        }
    }

    clearOtherSelectedOptions(options: any[], selectedOption: any) {
        options.forEach(option => {
            if (option !== selectedOption) {
                option.selected = false;
            }
        });
    }

    updateSelectedOption(selectedUuid: string) {
        for (let question of this.questions) {
            if (question.type === "selectQuestion") {

                const selectedIndex = question.data.options.findIndex(option => option.uuid === selectedUuid);

                if (selectedIndex !== -1) {
                    question.data.options.forEach((option, index) => {
                        if (index === selectedIndex) {
                            option.selected = true;
                        } else {
                            option.selected = false;
                        }
                    });
                }
            }
        }
    }

    updateOneOption(selectedUuid: string) {
        for (let question of this.questions) {
            if (question.type === "oneOptionQuestion") {
                question.data.options.forEach(option => {
                    if (question.data.options.some(opt => opt.uuid === selectedUuid)) {
                        option.selected = (option.uuid === selectedUuid);
                    }
                });
            }
        }
    }

    transformQuillData(term: any): SafeHtml {
        if (term) {
            return this._sanitizer.bypassSecurityTrustHtml(term);
        }
        return '';
    }

    showTerms() {
        const hasContactType = this.questions?.some(question => question.type === 'contact');
        const customTerms = this.customTerms?.length > 0;

        return hasContactType && customTerms;
    }

}
