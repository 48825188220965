<div *ngIf="applicationStateService.currentState === appState.READY else error">
    <div class="poll" [ngStyle]="{'background-color': pollStyles.backgroundColor}">
        <div class="poll-container" *ngIf="questions">
            <ng-container *ngTemplateOutlet="headerPreview; context: { $implicit: getHeader() }">
            </ng-container>
        </div>

        <form (ngSubmit)="onPollDataSubmit()" *ngIf="!pollSubmitted">
            <ng-container *ngFor="let question of questions">
                <mat-card class="poll-container mat-elevation-z0 question-preview"
                          *ngIf="question.type !== 'header' && question.type !== 'contact' && question.data.image_src !== '/assets/images/image-placeholder.png'"
                          id="preview-{{question.uuid}}">
                    <ng-container [ngSwitch]="question.type">
                        <ng-container *ngSwitchCase="'shortQuestion'">
                            <ng-container
                                *ngTemplateOutlet="shortQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'longQuestion'">
                            <ng-container
                                *ngTemplateOutlet="longQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'oneOptionQuestion'">
                            <ng-container
                                *ngTemplateOutlet="oneOptionQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'multipleOptionQuestion'">
                            <ng-container
                                *ngTemplateOutlet="multipleOptionQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'lineScaleQuestion'">
                            <ng-container
                                *ngTemplateOutlet="lineScaleQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'selectQuestion'">
                            <ng-container
                                *ngTemplateOutlet="selectQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'gridOneQuestion'">
                            <ng-container
                                *ngTemplateOutlet="gridOneQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'gridQuestion'">
                            <ng-container
                                *ngTemplateOutlet="gridQuestionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                        <ng-container *ngSwitchCase="'fileSection'">
                            <ng-container
                                *ngTemplateOutlet="fileSectionPreview; context: { $implicit: question }"></ng-container>
                        </ng-container>
                    </ng-container>
                </mat-card>
            </ng-container>

            <mat-card class="poll-container mat-elevation-z0 question-preview" *ngIf="hasContactSection()">
                <ng-container *ngTemplateOutlet="contactSection; context: { $implicit: getContactSection() }"></ng-container>
            </mat-card>
            <mat-card *ngIf="showTerms()" class="poll-container">
                <div class="options-left" *ngFor="let term of customTerms; let i = index">
                    <mat-checkbox
                        class="options"
                        color="primary"
                        [(ngModel)]="term.selected"
                        [name]="'term' + i"
                        (change)="anyRequiredFieldEmpty()"
                        required>
                        <span class="option-container" [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}">
                            <span [innerHtml]="transformQuillData(term.content)"></span>
                            <span style="color:red">&nbsp; *</span>
                        </span>
                    </mat-checkbox>
                </div>
            </mat-card>
            <ng-container *ngIf="!anyRequiredFieldEmpty()">
                <mat-card class="poll-container">
                    <div class="d-flex justify-content-end">
                        <button mat-stroked-button type="submit" class="button-rounded primary poll-btn" color="primary" [disabled]="this.isPollActive === '0' || sendBtnClicked">
                            Prześlij
                        </button>
                    </div>
                </mat-card>
            </ng-container>
            <ng-container *ngIf="anyRequiredFieldEmpty()">
                <mat-card class="poll-container">
                    <div class="d-flex justify-content-end">
                        <button mat-stroked-button type="submit" class="button-rounded primary poll-btn" color="primary" [disabled]="true">
                            Prześlij
                        </button>
                    </div>
                </mat-card>
            </ng-container>
        </form>


        <ng-template #headerPreview let-header>
            <mat-card *ngIf="this.isPollActive === '0'" class="poll-container">
                <span class="poll-disabled">Ankieta nie jest aktywna.</span>
            </mat-card>
            <mat-card *ngIf="header.data" class="poll-container">
                <h2 class="question-title" *ngIf="!pollSubmitted" [ngStyle]="{'font-size': pollStyles.titleFontSize + 'px'}">
                    {{ header.data.title }}
                </h2>
                <p *ngIf="!pollSubmitted">{{ header.data.description }}</p>
                <ng-container *ngIf="hasRequiredQuestion && !pollSubmitted">
                    <span class="validation-label" aria-label="Wymagane">* Wskazuje wymagane pytanie</span>
                </ng-container>
                <div *ngIf="pollSubmitted">
                    <div class="message" *ngIf="!sendIssue; else sendIssueMessage">
                        <h2>Twoja odpowiedź została przesłana.</h2>
                    </div>

                    <ng-template #sendIssueMessage>
                        <div class="message">
                            <h1>Twoja odpowiedź została przesłana.</h1>
                            <h3>Sprawdź swoją skrzynkę e-mail i aktywuj sprawę.</h3>
                        </div>
                    </ng-template>
                </div>
            </mat-card>
        </ng-template>

        <ng-template #shortQuestionPreview let-question>
            <h3 class="question-title question-font-weight"
                [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">{{ question.data.title }}
                <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
                </ng-container>
            </h3>
            <mat-form-field appearance="outline">
                <mat-label>Odpowiedź</mat-label>
                <textarea [(ngModel)]="question.data.value" matInput [required]="question.data.required" [maxlength]="256"></textarea>
                <mat-hint align="end">{{ question.data.value.length || 0 }}/{{ 256 }}</mat-hint>
            </mat-form-field>
        </ng-template>

        <ng-template #longQuestionPreview let-question>
            <h3 class="question-title question-font-weight"
                [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">{{ question.data.title }}
                <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
                </ng-container>
            </h3>
            <mat-form-field appearance="outline">
                <mat-label>Odpowiedź</mat-label>
                <textarea matInput [(ngModel)]="question.data.value" [required]="question.data.required" [maxlength]="512"></textarea>
                <mat-hint align="end">{{ question.data.value.length || 0 }}/{{ 512 }}</mat-hint>
            </mat-form-field>
        </ng-template>

        <ng-template #oneOptionQuestionPreview let-question>
            <h3 class="question-title question-font-weight"
                [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">{{ question.data.title }}
                <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
                </ng-container>
            </h3>
            <mat-radio-group [required]="question.data.required" [name]="'one_option_question' + '_' + question.uuid"
                             [(ngModel)]="question.data.options[i].selected" class="options-left flex-column"
                             *ngFor="let option of question.data.options; let i = index">

                <mat-radio-button class="options options-left" color="primary" (change)="updateOneOption(option.uuid)"
                                  [value]="true">
                    <span class="option" [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}"> {{ question.data.options[i].value }}</span>
                </mat-radio-button>
            </mat-radio-group>
        </ng-template>

        <ng-template #multipleOptionQuestionPreview let-question>
            <h3 class="question-title question-font-weight"
                [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">{{ question.data.title }}
                <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
                </ng-container>
            </h3>
            <div class="options-left" *ngFor="let option of question.data.options; let i = index">
                <mat-checkbox class="options" [(ngModel)]="question.data.options[i].selected" color="primary" [required]="question.data.required">
                <span class="option"
                      [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}"> {{ question.data.options[i].value }}
                </span>
                </mat-checkbox>
            </div>
        </ng-template>

    <ng-template #lineScaleQuestionPreview let-question>
        <h3 class="question-title question-font-weight"
            [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">{{ question.data.title }}
            <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
            </ng-container>
        </h3>
        <div class="options-center line-scale flex-center">
            <span class="wrapping line-scale-value-grey" [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}"> {{ question.data.line_scale[0].label_from.text }}</span>
            <ng-container class="d-flex flex-column"
                          *ngFor="let number of range(question.data.line_scale[0].label_from.value, question.data.line_scale[1].label_to.value + 1)">
                <mat-radio-group [(ngModel)]="question.data.value">
                    <mat-radio-button color="primary" [value]="number"
                                      [name]="'line_scale_question' + '_' + question.uuid"
                                      [required]="question.data.required"
                                      [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}">
                        <span class="line-scale-value-grey">{{ number }}</span>
                    </mat-radio-button>
                </mat-radio-group>
            </ng-container>
            <span class="line-scale-last wrapping line-scale-value-grey"
                  [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}"> {{ question.data.line_scale[1].label_to.text }}
            </span>
            </div>
        </ng-template>

    <ng-template #selectQuestionPreview let-question>
        <h3 class="question-title question-font-weight"
            [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">{{ question.data.title }}
            <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
            </ng-container>
        </h3>
        <mat-form-field appearance="outline" class="mat-select-input">
            <mat-label>Odpowiedz na to pytanie</mat-label>
            <mat-select (selectionChange)="updateSelectedOption($event.value)">
                <mat-option *ngFor="let option of question.data.options; let i = index" [value]="option.uuid">
                    {{ i + 1 }}. {{ option.value }}
                </mat-option>
            </mat-select>
        </mat-form-field>
    </ng-template>

    <ng-template #gridOneQuestionPreview let-question>
        <h3 class="question-title question-font-weight"
            [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">
                {{ question.data.title }}
                <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
                </ng-container>
            </h3>
            <div class="scrollable-container">
                <div class="table-wrapper">
                    <div class="table-wrapper__header" *ngIf="question.data.columns.length > 0">
                        <div class="table-wrapper__row">
                            <div class="table-wrapper__cell-data"></div>
                            <div class="table-wrapper__cell" *ngFor="let column of question.data.columns; let j = index"
                                 [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}">
                                {{ column.value }}
                            </div>
                        </div>
                    </div>
                    <div class="table-wrapper__row-group table-wrapper__single-row"
                         *ngFor="let option of question.data.rows; let i = index">
                        <div class="table-wrapper__row-container">
                            <div class="table-wrapper__cell-data">
                                {{ option.value }}
                            </div>
                            <ng-container *ngFor="let column of question.data.columns; let j = index">
                                <div class="table-wrapper__cell">
                                    <mat-radio-button class="grid-question" color="primary"
                                                      [required]="question.data.required"
                                                      (change)="updateGridOneQuestionValue(i, j, true, question.uuid)"
                                                      [name]="'grid_question' + '_' + i + '_' + question.uuid">
                                    </mat-radio-button>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #gridQuestionPreview let-question>
            <h3 class="question-title question-font-weight" [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">
                {{ question.data.title }}
                <ng-container *ngIf="question.data.required"><span class="validation-star" aria-label="Wymagane">*</span>
                </ng-container>
            </h3>
            <div class="scrollable-container">
                <div class="table-wrapper">
                    <div class="table-wrapper__header" *ngIf="question.data.columns.length > 0">
                        <div class="table-wrapper__row">
                            <div class="table-wrapper__cell-data"></div>
                            <div class="table-wrapper__cell" *ngFor="let column of question.data.columns; let j = index"
                                 [ngStyle]="{'font-size': pollStyles.answerFontSize + 'px'}">
                                {{ column.value }}
                            </div>
                        </div>
                    </div>
                    <div class="table-wrapper__row-group table-wrapper__single-row"
                         *ngFor="let option of question.data.rows; let i = index">
                        <div class="table-wrapper__row-container">
                            <div class="table-wrapper__cell-data">
                                {{ option.value }}
                            </div>
                            <ng-container *ngFor="let column of question.data.columns; let j = index">
                                <div class="table-wrapper__cell">
                                    <mat-checkbox class="grid-question" color="primary"
                                                  [required]="question.data.required"
                                                  (change)="updateGridQuestionValue(i, j, $event.checked, question.uuid)"
                                                  [name]="'grid_question' + '_' + i + '_' + question.uuid">
                                    </mat-checkbox>
                                </div>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <ng-template #fileSectionPreview let-question>
            <div *ngIf="question.data.img_src !== '/assets/images/image-placeholder.png'" class="preview-image-wrapper">
                <a *ngIf="question.data.href"
                   [href]="question.data.href"
                   target="_blank">
                    <img class="preview-image-wrapper-img" [src]="question.data.image_src" alt="" crossorigin="anonymous">
                </a>
                <a *ngIf="!question.data.href">
                    <img class="preview-image-wrapper-img" [src]="question.data.image_src" alt="" crossorigin="anonymous">
                </a>
            </div>
        </ng-template>

        <ng-template #contactSection let-question>
            <h3 class="question-title" [ngStyle]="{'font-size': pollStyles.questionFontSize + 'px'}">
                Dane kontaktowe <span class="validation-star" aria-label="Wymagane">*</span>
            </h3>

            <ng-container *ngFor="let field of question.data">
                <mat-form-field appearance="outline">
                    <mat-label>{{field.title}}</mat-label>
                    <input matInput [(ngModel)]="field.value" [required]="true" [maxlength]="250">
                </mat-form-field>
            </ng-container>
        </ng-template>
    </div>
</div>

<ng-template #error>
    <app-error-404
        *ngIf="applicationStateService.currentState === appState.ERROR">
    </app-error-404>
</ng-template>
