import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { WidgetFormData } from '../common/interfaces/widget-form-data.interface';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApplicationStateService } from './application-state.service';

@Injectable({
    providedIn: 'root'
})

export class FormWidgetService {
    formApiUrl = environment.apiUrl + '/widget_issues/add';

    constructor(
        private http: HttpClient,
        private applicationStateService: ApplicationStateService) { }

    public transformName(name) {
        name = name.trim().toLowerCase().replace(/[^a-zA-Z0-9ąćęłńóśźżĄĆĘŁŃÓŚŹŻ]/g, '');

        return name.charAt(0).toUpperCase() + name.slice(1);
    }

    getFormWidgetSettings(widgetId: string): Observable<any> {
        const formApiUrl = environment.apiUrl + `/chat_widgets/settings/${widgetId}`;
        const InterceptorSkipHeader = 'X-Skip-Interceptor';

        return this.http.get(formApiUrl, {headers: new HttpHeaders().set(InterceptorSkipHeader, '')})
    }

    postFormData(data: WidgetFormData): Observable<any> {
        const InterceptorSkipHeader = 'X-Skip-Interceptor';

        const customerData = this.applicationStateService.customerData(),
        formData = new FormData();

        let issueSubject = data?.issueSubject || data.userMessage.substring(0, 100);

        formData.append('data[WidgetIssue][issue_subject]', issueSubject);
        formData.append('data[WidgetIssue][issue_sentence_body]', data.userMessage);
        formData.append('data[WidgetIssue][company]', data?.userCompany);
        formData.append('data[User][firstname]', this.transformName(data.userName));
        formData.append('data[User][lastname]', this.transformName(data.userLastname));
        formData.append('data[User][email]', data.userEmail);
        formData.append('data[User][phone]', data.userPhone);
        formData.append('data[OauthClient][customer_id]', customerData.customer_id);
        formData.append('data[OauthClient][client_id]', customerData.client_id);
        formData.append('data[WidgetIssue][external_hash]', customerData.external_hash);
        formData.append('data[WidgetIssue][type]', 'form');

        if (data.termId) {
            data.termId.forEach(id => {
                formData.append('data[ClientTCAcceptance][tc_id][]', id);
            });
        }

        return this.http.post(this.formApiUrl, formData, {headers: new HttpHeaders().set(InterceptorSkipHeader, '')});
    }

}
