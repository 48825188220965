import {AfterViewInit, Component, OnInit } from '@angular/core';
import {WfirmaCommunicationService} from '../services/wfirma-communication.service';
import {WfirmaMessageType} from '../common/enums/wfirma-message-type';
import {UserStoreService} from '../services/user-store.service';
import {UsersService} from '../services/api/users.service';
import {AuthService} from '../services/auth/auth.service';
import {distinctUntilChanged, filter, switchMap, tap } from 'rxjs/operators';
import {ConfigStyleService} from '../services/config-style.service';
import {WidgetSettingsInterface} from '../common/interfaces/widget.interface';
import {configMockup} from '../../assets/scripts/mockup';
import {TranslateService} from '@ngx-translate/core';
import {take, skip, catchError} from 'rxjs/operators';
import {of} from 'rxjs';
import {ChatTheme} from "../common/enums/chat-theme";


@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit, AfterViewInit {

    employeeName = '';
    employeeDescription = '';
    employeeAvatar = '';
    isLoading = true;
    config: WidgetSettingsInterface;
    darkMode = ChatTheme.dark;

    settingsConfig: any;

    constructor(
        private wfirmaCommunicationService: WfirmaCommunicationService,
        private userStoreService: UserStoreService,
        private usersService: UsersService,
        private authService: AuthService,
        public translate: TranslateService,
        private _configStyleService: ConfigStyleService
    ) {}

  ngAfterViewInit(): void {
      this._configStyleService.getConfig$()
          .pipe(
              skip(1),
              take(1),
              catchError(error => {
                  console.error('An error occurred, default values assigned:', error);
                  this.config = configMockup as WidgetSettingsInterface;
                  return of(null);
              })
          )
          .subscribe((config) => {
            this.config = config.settings;
            this.isLoading = false;
      });
  }

    ngOnInit() {
        this.authService.isLoggedIn$
            .pipe(
                distinctUntilChanged(),
                filter(isLoggedIn => isLoggedIn),
                switchMap(() => this.usersService.getChatUserId()),
                tap(userId => {
                    if (!+userId) {
                        this.employeeName = this.translate.instant('TOOLBAR.ASSIGN_EXPERT');
                    }
                }),
                filter(userId => !!+userId),
                switchMap(chatUserId => this.userStoreService.getUser(chatUserId)),
            )
            .subscribe(
                user => {
                    if (!user) {
                        return;
                    }

                    this.employeeDescription = user.description || '';
                    this.employeeAvatar = user.avatar || '';

                    if (!user.firstname && !user.lastname) {
                        this.employeeName = this.translate.instant('TOOLBAR.ASSIGN_EXPERT');
                    } else {
                        this.employeeName = user.firstname || '';

                        if (user.lastname) {
                            this.employeeName = this.employeeName + ' ' + user.lastname || '';
                        }
                    }
                },
                () => this.employeeName = this.translate.instant('TOOLBAR.ASSIGN_EXPERT')
            );
    }

    close() {
        this.wfirmaCommunicationService.sendMessageToParent(WfirmaMessageType.SEND_CLOSE);
    }

    protected readonly blur = blur;
}
