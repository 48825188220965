import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {ChatMessageComponent} from './chat/chat-message/chat-message.component';
import {ChatListComponent} from './chat/chat-list/chat-list.component';
import {ChatComponent} from './chat/chat/chat.component';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {AuthInterceptorService} from './services/auth/auth-interceptor.service';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {WaitingComponent} from './waiting/waiting.component';
import {ToolbarComponent} from './toolbar/toolbar.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {FileUploaderComponent} from './chat/file-uploader/file-uploader.component';
import {ChatMessageFileComponent} from './chat/chat-message-file/chat-message-file.component';
import {ChatMessageTypingComponent} from './chat/chat-message-typing/chat-message-typing.component';
import {ChatToolbarComponent} from './chat/chat-toolbar/chat-toolbar.component';
import {ErrorComponent} from './error/error.component';
import {WelcomeBoxComponent} from './chat/welcome-box/welcome-box.component';
import {MatSelectModule} from '@angular/material/select';
import {MatFormFieldModule} from '@angular/material/form-field';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {FormComponent} from './form/form.component';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {ExtractLinkInfoPipe} from './pipes/extract-text.pipe';
import {ExtractTextAfterLinkPipe} from './pipes/extract-text-after-link.pipe';
import {NgxUploaderModule} from 'ngx-uploader';
import {FooterComponent} from './chat/footer/footer.component';
import {MatInputModule} from '@angular/material/input';
import {PollComponent} from './poll/poll.component';
import {MatRadioModule} from '@angular/material/radio';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {FullpageFormComponent} from './fullpage-form/fullpage-form.component';
import {Error404Component} from './error/error-404/error-404.component';
import {CommonModule} from '@angular/common';

export function httpTranslateLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}

@NgModule({
    declarations: [
        AppComponent,
        ToolbarComponent,
        ChatMessageComponent,
        ChatListComponent,
        ChatComponent,
        ChatToolbarComponent,
        WaitingComponent,
        ChatMessageTypingComponent,
        FileUploaderComponent,
        ChatMessageFileComponent,
        ErrorComponent,
        ChatMessageFileComponent,
        WelcomeBoxComponent,
        FormComponent,
        ExtractLinkInfoPipe,
        ExtractTextAfterLinkPipe,
        FooterComponent,
        PollComponent,
        FullpageFormComponent,
        Error404Component
    ],
  imports: [
      CommonModule,
      BrowserModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      MatToolbarModule,
      MatIconModule,
      MatButtonModule,
      MatProgressSpinnerModule,
      MatTooltipModule,
      MatSelectModule,
      MatFormFieldModule,
      ReactiveFormsModule,
      MatCardModule,
      MatCheckboxModule,
      NgxUploaderModule,
      MatInputModule,
      MatSnackBarModule,
      MatRadioModule,
      TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpTranslateLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptorService,
            multi: true
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
