import {Injectable} from '@angular/core';
import * as io from 'socket.io-client';
import {Observable, Subject} from 'rxjs';
import {environment} from '../../environments/environment';
import {AuthService} from './auth/auth.service';
import {SocketMessage} from '../common/interfaces/socket-message';

@Injectable({
    providedIn: 'root'
})
export class SocketsService {
    private socket;
    private lastThreadId: number;

    messages$: Subject<SocketMessage> = new Subject();
    socketsConnected$: Subject<boolean> = new Subject();
    typingMessage$: Subject<boolean> = new Subject();

    constructor(private authService: AuthService) {
    }

    connect() {
        if (this.socket && this.socket.connected) {
            return;
        }

        if (this.socket) {
            this.socket.close();
        }

        this.socket = io(environment.socketsUrl, {
                transports: ['websocket'],
                query: {token: this.authService.getWsToken(), userId: this.authService.getUserId()}
            }
        );

        this.addSocketListeners();
    }

    disconnect() {
        if (this.socket) {
            this.socket.off('disconnect');
            this.socket.disconnect();
        }
    }

    private onConnect() {
        this.socket.on('connect', () => this.socketsConnected$.next(true));
    }

    private onDisconnect() {
        this.socket.on('disconnect', reason => {
            console.error('Socket disconnected', reason);
            this.socketsConnected$.next(false);
        });
    }

    private onErrors() {
        this.socket.on('error', err => {
            console.error('Błąd z websocket', err);
        });

        this.socket.on('connect_timeout', err => {
            console.error('Błąd połączenia z websocket (timeout)', err);
        });

        this.socket.on('connect_error', err => {
            console.error('Błąd połączenia z websocket', err);
        });
    }

    private addSocketListeners() {
        this.onConnect();
        this.onErrors();
        this.onDisconnect();
    }

    private leaveMessageChannel(threadId: number) {
        if (this.socket) {
            this.socket.emit('leaveChatMessagesRoom', threadId);
            this.socket.off('newChatMessage');
            this.socket.off('ownerTypingMessage');
            this.lastThreadId = null;
        }
    }

    joinMessageChannel(threadId: number) {
        if (this.socket && threadId) {

            if (this.lastThreadId) {
                this.leaveMessageChannel(this.lastThreadId);
            }

            this.lastThreadId = threadId;

            this.socket.emit('joinChatMessagesRoom', threadId);

            this.socket.on('newChatMessage', data => {
                const issueSentenceChanged = JSON.parse(data);

                this.messages$.next(issueSentenceChanged);
            });

            this.socket.on('ownerTypingMessage', () => {
                this.typingMessage$.next(true);
            });
        }
    }

    typing(threadId: number) {
        this.socket.emit('clientTypingMessage', threadId);
    }

    sendAiMessage(text) {
        this.socket.emit('sendAiMessage', text);
    }

    receiveAiMessage() {
        return new Observable(observer => {
            this.socket.on('receiveAiMessage', (answer) => {
                observer.next(answer);
            });

            // Cleanup on unsubscribe
            return () => {
                this.socket.off('receiveAiMessage');
            };
        });
    }
}
